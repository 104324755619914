import { ImageStudioStyles } from "@typeface-ai/typeface-embed-react/lib/esm/styles";

export const STYLES: {
  [key: string]: Partial<ImageStudioStyles>;
} = {
  typeface: {},
  msft: {
    borderRadius: "8px",
    backgroundColor: "#f3f4ff",
    textfieldColor: "rgba(0, 0, 0, 0.03)",
    primaryButton: {
      backgroundColor: "#003293",
      fontColor: "white",
      border: "0px",
    },
    secondaryButton: {
      backgroundColor: "transparent",
      fontColor: "#003293",
      border: "1px solid #003293",
    },
  },
};

export const LANGUAGES = [
  {
    id: 1,
    label: "Danish",
    code: "da",
  },
  {
    id: 2,
    label: "Dutch",
    code: "nl",
  },
  {
    id: 3,
    label: "English",
    code: "en",
  },
  {
    id: 4,
    label: "French",
    code: "fr",
  },
  {
    id: 5,
    label: "German",
    code: "de",
  },
  {
    id: 6,
    label: "Italian",
    code: "it",
  },
  {
    id: 7,
    label: "Spanish",
    code: "es",
  },
  {
    id: 8,
    label: "Testing Mode",
    code: "masked",
  },
];
