import { useState } from "react";
import "./App.css";
import { Button, Dialog, Paper, Stack, styled, TextField } from "@mui/material";
import {
  TypefaceEmbedIDP,
  TypefaceEmbedOutputFormat,
  TypefaceEmbedUseCase,
  TemplateType,
  TextBlendActionTypes,
  TypefaceOutputSchema
} from "@typeface-ai/typeface-embed-react/lib/esm/model";
import {
  shouldUsePopUpWindow,
  TypefacePluginIFrame,
  TypefacePluginPopUp,
} from "@typeface-ai/typeface-embed-react/lib/esm/index";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import job_schema from '../src/resources/job_schema.json';
import { OnEventMessage } from "@typeface-ai/typeface-embed-react/lib/esm/definitions";

const MainContainer = styled(Paper)({
  backgroundColor: "white",
  width: "800px",
  padding: "30px",
});

const Editor = styled(TextField)({
  width: "calc(100%-40px)",
});

const TriggerDialogButton = styled(Button)({
  width: "250px",
});

enum OrientationType {
  MOBILE = "Mobile",
  TABLET = "Tablet",
  DESKTOP = "Desktop"
} 

function Embed() {
  const [value, setValue] = useState("");
  const [showDialog, setShowDialog] = useState(false);

  const [usePopUp] = useState(shouldUsePopUpWindow());

  const [userToken, setUserToken] = useState<string | undefined>(undefined);
  const [projectId, setProjectId] = useState<string>();

  const [selectedUsecase, setSelectedUsecase] = useState<TypefaceEmbedUseCase>(
    TypefaceEmbedUseCase.Default
  );
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateType|string>(
    TemplateType.JobPost
  );
  const [selectedOrientationType, setSelectedOrientationType] = useState<OrientationType>(OrientationType.DESKTOP)
  const [selectedEnv, setSelectedEnv] = useState("dev");
  const [selectedOutputFormat, setSelectedOutputFormat] = useState<TypefaceEmbedOutputFormat>(TypefaceEmbedOutputFormat.HTML)

  const getParameterMap = () => {
    if (selectedUsecase === TypefaceEmbedUseCase.Image) {
      return {
        prompt: "Stunning shot of Black Coffee and coffee beans on a wooden table.",
        width: 512,
        height: 512
      }
    } else if (selectedUsecase === TypefaceEmbedUseCase.Template) {
      if (selectedTemplate === TemplateType.JobPost) {
        return {
          template: TemplateType.JobPost,
          parameters: {
            JobTitle: "Senior Product Manager",
            JobFamily: "Product Management",
            JobLevel: "Advanced (Professional IC)",
            CompanyName: "Typeface",
            IncludeJobSpecialty: true,
            JobSpeciality: "Computer Vision",
            JobSpecialityDescription: "Computer vision engineers collect data to advance the ability of computers to solve problems by making sense of images.",
            OutputSchema: job_schema
          },
        };
      } else if (selectedTemplate === TemplateType.BlogPost) {
        return {
          template: TemplateType.BlogPost,
          autoGenerate: true,
          parameters: {
            Name: "Generative AI",
            Content: "Describe various applications of generative AI",
            StyleName: "Professional",
            ImageDescription: "Human brain in a computer, stunning shot."
          },
        };
      } else if (selectedTemplate === TemplateType.ImageStudio) {
        return {
          template: TemplateType.ImageStudio,
          parameters: {
            InputPrompt: "Stunning shot of Black Coffee and coffee beans on a wooden table."
          }
        }
      } else if (selectedTemplate === TemplateType.Email) {
        return {
          template: TemplateType.Email,
          parameters: {
            Purpose: "Request for a new feature",
            Context:
              "Typeface templates have been extremely useful. We would like to have new capabilities around collaboration",
            DynamicTargetAudienceSegment: "Young Adults",
            DynamicTargetAudience: "People between the age of 18 years to 40 years"
          },
        }
      } else if (selectedTemplate === TemplateType.TextBlend) {
        return {
          template: TemplateType.TextBlend,
          autoGenerate: true,
          parameters: {
            Action: TextBlendActionTypes.Summarize,
            IsContentSourceUri: true,
            ContentUri: "https://blog.typeface.ai/major-typeface-product-update-for-instant-ai-product-photography-and-audience-personalized-content/",
            StyleName: "Professional",
          }
        }
      } else if (selectedTemplate === TemplateType.GoogleAd) {
        // When Dynamic Audience feature is disabled
        return {
          template: TemplateType.GoogleAd,
          autoGenerate: false,
          parameters: {
            ProductName: "Cecilie apple juice",
            DisplayURL: "juice.com",
            ProductContext: "Fresh apple juice",
            StandoutCharacteristics: "organic apple juice",
            KeyWords: "apple juice",
            TargetAudience: "school childern"
          }
        }
      } else if (selectedTemplate === TemplateType.GoogleResponsiveAd) {
        // When Dynamic Audience feature is enabled
        return {
          template: TemplateType.GoogleResponsiveAd,
          autoGenerate: false,
          parameters: {
            BusinessName: "Cecilie apple juice",
            DisplayURL: "juice.com",
            Goal: "promote apple juice",
            StandoutCharacteristics: "Fresh apple juice",
            DynamicTargetAudienceSegment: "Young Adults",
            DynamicTargetAudience: "People between the age of 18 years to 40 years"
          }
        }
      } else if (selectedTemplate === TemplateType.CelebrationPost) {
        return {
          template: TemplateType.CelebrationPost,
          autoGenerate: false,
          parameters: {
            Title: "Celebrating September Birthdays and Anniversaries",
            Goal: "Wish employees a happy anniversary and birthday, support their commitment to the company. And express well wishes for the year ahead",
            StyleName: "Professional",
            ImageDescription: "Balloons and confetti to celebrate employee birthdays and anniversaries, highly detailed, warm tones "
          },
        }
      }
      
      else {
        return {
          template: '',
          parameters: {}
        }
      }
    }
  }

  const getTypefaceOrigin = () => {
    if (selectedEnv === "dev") {
      return "https://beta.typeface.ai";
    } else if (selectedEnv === "preview") {
      return 'https://kind-pond-08f7fc10f-preview.eastus2.3.azurestaticapps.net'
    } else if (selectedEnv === "prod") {
      return "https://app.typeface.ai";
    }
    else if (selectedEnv === "local-http") {
      return "http://localhost:3000";
    }
    else if (selectedEnv === "local-https") {
      return "https://localhost:3000";
    }
  };

  const getWidth = () => {
    if (selectedOrientationType === OrientationType.MOBILE) {
      return 600;
    }

    if (selectedOrientationType === OrientationType.TABLET) {
      return 820;
    }

    if (selectedUsecase === TypefaceEmbedUseCase.Image) {
      return 768;
    }

    return 1200;
  };

  const pluginProps = {
    idp: TypefaceEmbedIDP.Typeface,
    useCase: selectedUsecase,
    typefaceOrigin: getTypefaceOrigin(),
    sourceApp: "google",
    hideHeader: false,
    onEvent: (event : OnEventMessage) => {
      console.log("On Event called with data string", event)
    },
    parameters: getParameterMap(),
    onApply: (content: any) => {
      console.log("On Apply called")
      setValue(content);
      setShowDialog(false);
    },
    styles: {
      height: 800,
      width: getWidth(),
      embedFooterStyle: {
        padding: '5px 0',
        placement: "top"
      }
    },
    onCancel: () => {
      console.log("Cancel called")
      setShowDialog(false)
    },
    outputFormat: selectedOutputFormat,
  };

  const triggerTypefacePlugin = () => {
    if (usePopUp) {
      TypefacePluginPopUp(pluginProps);
    } else {
      setShowDialog(true);
    }
  };

  const handleDropdownChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value as unknown as TypefaceEmbedUseCase;
    setSelectedUsecase(selectedValue);
  };

  const handleOrientationChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value as unknown as OrientationType
    setSelectedOrientationType(selectedValue)
  }

  const handleEnvironmentChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    setSelectedEnv(selectedValue);
  };

  const handleTemplateChange = (event: SelectChangeEvent) => {
    if (event.target.value !== 'all-templates') {
      const selectedValue = event.target.value as unknown as TemplateType;
      setSelectedTemplate(selectedValue);
    } else {
      setSelectedTemplate(event.target.value)
    }

  };

  const handleOutputFormatChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value as TypefaceEmbedOutputFormat
    setSelectedOutputFormat(selectedValue)
  }

  return (
    <div className="App">
      <header className="App-header">
        <MainContainer elevation={2}>
          <Stack gap={2}>
            <FormControl>
              <InputLabel id="usecase-label">Environment</InputLabel>
              <Select
                labelId="usecase-label"
                id="usecase"
                value={selectedEnv}
                label="Environment"
                onChange={handleEnvironmentChange}
              >
                <MenuItem value="dev">dev</MenuItem>
                <MenuItem value="prod">prod</MenuItem>
                <MenuItem value="preview">preview</MenuItem>
                <MenuItem value="local-http">local http</MenuItem>
                <MenuItem value="local-https">local https</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="usecase-label">Use case</InputLabel>
              <Select
                labelId="usecase-label"
                id="usecase"
                value={selectedUsecase}
                label={'Use case'}
                onChange={handleDropdownChange}
              >
                <MenuItem value={TypefaceEmbedUseCase.Default}>Default</MenuItem>
                {/* TODO: Confirm if the following 4 usecases is still needed and remove if not */}
                <MenuItem value={TypefaceEmbedUseCase.Template}>Template</MenuItem>
                <MenuItem value={TypefaceEmbedUseCase.Image}>Image</MenuItem>
                <MenuItem value={TypefaceEmbedUseCase.ChooseDocument}>Choose Document</MenuItem>
                <MenuItem value={TypefaceEmbedUseCase.TextAnalyzer}>Text Analyzer</MenuItem>
              </Select>
            </FormControl>
            {selectedUsecase === TypefaceEmbedUseCase.Template && (
              <FormControl>
                <InputLabel id="usecase-label">Template</InputLabel>
                <Select
                  labelId="usecase-label"
                  id="usecase"
                  label={'Template'}
                  value={selectedTemplate}
                  onChange={handleTemplateChange}
                >
                  <MenuItem value={TemplateType.JobPost}>Job Post</MenuItem>
                  <MenuItem value={TemplateType.BlogPost}>Blog Post</MenuItem>
                  <MenuItem value={TemplateType.Email}>Email</MenuItem>
                  <MenuItem value={TemplateType.ImageStudio}>Image Studio</MenuItem>
                  <MenuItem value={TemplateType.TextBlend}>Text Blend</MenuItem>
                  <MenuItem value={TemplateType.GoogleAd}>Google Ad</MenuItem>
                  <MenuItem value={TemplateType.GoogleResponsiveAd}>Google Responsive Ad</MenuItem>
                  <MenuItem value={TemplateType.CelebrationPost}>Celebrations Post</MenuItem>
                  <MenuItem value={'all-templates'}>All Templates</MenuItem>
                </Select>
              </FormControl>
            )}
            <FormControl>
              <InputLabel id="output-format-label">Output format</InputLabel>
              <Select
                labelId="output-format-label"
                id="output-format"
                value={selectedOutputFormat}
                label={'Output format'}
                onChange={handleOutputFormatChange}
              >
                {
                  Object.values(TypefaceEmbedOutputFormat).map((item) => {
                    return (
                      <MenuItem value={item}>
                        {item}
                      </MenuItem>     
                    )
                  })
                }
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="orientation-label">Orientation</InputLabel>
              <Select
                labelId="orientation-label"
                id="orientation"
                value={selectedOrientationType}
                label={'Orientation'}
                onChange={handleOrientationChange}
              >
               {
                  Object.values(OrientationType).map((item) => {
                    return (
                      <MenuItem value={item}>
                        {item}
                      </MenuItem>     
                    )
                  })
                }
              </Select>
            </FormControl>
            <TriggerDialogButton
              variant="contained"
              onClick={() => {
                triggerTypefacePlugin();
              }}
            >
              Draft with Typeface
            </TriggerDialogButton>
            <Editor
              multiline={true}
              minRows={30}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder={"Your job post editor here ..."}
            />
          </Stack>
          {!usePopUp && (
            <Dialog open={showDialog} maxWidth={false}>
              {/* Keep width of the plugin to around 700px for the current experience */}
              <Paper>
                <TypefacePluginIFrame {...pluginProps} />
              </Paper>
            </Dialog>
          )}
        </MainContainer>
      </header>
    </div>
  );
}

export default Embed;
