import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TPI from "./TPI";
import Embed from "./Embed";
import PSN from "./PSN";

import { ToastContainer } from "react-toastify";

export const App = () => {
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        limit={4}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        className="toast-message"
      />
      <Router basename="/">
        <Routes>
          <Route path={"/"} element={<Embed />} />
          <Route path={"/tpi"} element={<TPI />} />
          <Route path={"/psn"} element={<PSN />} />
        </Routes>
      </Router>
    </>
  );
};
