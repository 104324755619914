import { useState } from "react";
import "./App.css";
import { Button, Dialog, Paper, Stack, styled, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import axios from "axios";
import {
  TypefaceEmbedIDP,
  TypefaceEmbedOutputFormat,
  TypefaceEmbedUseCase,
  EditorType,
  ExternalRaiCheckOutput
} from "@typeface-ai/typeface-embed-react/lib/esm/model";
import {
  shouldUsePopUpWindow,
  TypefacePluginBlend,
  TypefacePluginAssetEdit
} from "@typeface-ai/typeface-embed-react/lib/esm";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { OnEventMessage } from "@typeface-ai/typeface-embed-react/lib/esm/definitions";
import { LANGUAGES, STYLES } from "./styles";

const USER = {
  TENANT_ID: "1234",
  ACCOUNT_ID: "aedb26f7-6db9-4551-b922-6d9e001e5b54",
  PROJECT_ID: "328591",
  FEED_ID: "d62d3269-e5c2-411f-929c-9c441195993e",
  FEED_ITEM_ID: "20b00c4c-6769-4ce3-9445-3b38b998201b",
  ASSET_ID: "2982861",
  ASSET_CATALOG_ID: "230884"
}

const MainContainer = styled(Paper)({
  backgroundColor: "white",
  width: "800px",
  padding: "30px",
});

const Editor = styled(TextField)({
  width: "calc(100%-40px)",
});

const TriggerDialogButton = styled(Button)({
  width: "250px",
});

function PSN() {
  const [value, setValue] = useState("");
  const [showDialog, setShowDialog] = useState(false);

  const [usePopUp] = useState(shouldUsePopUpWindow());

  const [userToken, setUserToken] = useState<string | undefined>(undefined);
  const [projectId, setProjectId] = useState<string>(USER.PROJECT_ID);
  const [tenantId, setTenantId] = useState<string>(USER.TENANT_ID);
  const [feedItemId, setFeedItemId] = useState<string>(USER.FEED_ITEM_ID);
  const [feedId, setFeedId] = useState(USER.FEED_ID)
  const [accountId, setAccountId] = useState<string>(USER.ACCOUNT_ID);
  const [assetId, setAssetId] = useState<string>(USER.ASSET_ID);
  const [assetCatalogId, setAssetCatalogId] = useState(USER.ASSET_CATALOG_ID)
  const [selectedLang, setSelectedLang] = useState('en')

  const [selectedUseCase, setSelectedUseCase] = useState<TypefaceEmbedUseCase>(
    TypefaceEmbedUseCase.AssetEdit
  );
  const [selectedEnv, setSelectedEnv] = useState("dev");
  const [selectedEditorType, setSelectedEditorType] = useState<EditorType>(
    EditorType.Edit
  );
  const [selectedTheme, setSelectedTheme] = useState("typeface");

  const [applyRaiCheck, setApplyRaiCheck] = useState(true)
  const [imageContinue, setImageContinue] = useState(true)
  const [promptContinue, setPromptContinue] = useState(true)

  const getParameterMap = () => {
    return {
      accountId: accountId?.trim(),
      ...(selectedUseCase === TypefaceEmbedUseCase.FeedBlend && {
        projectId: projectId?.trim(),
        feedItemId: feedItemId?.trim(),
        feedId: feedId?.trim(),
        editorType: selectedEditorType,
      }),
      ...(selectedUseCase === TypefaceEmbedUseCase.AssetEdit && {
        assetId: assetId?.trim(),
        assetCatalogId: assetCatalogId?.trim()
      }),
    };
  }

  const getTypefaceOrigin = () => {
    if (selectedEnv === "dev") {
      return "https://beta.typeface.ai";
    } else if (selectedEnv === "prod") {
      return "https://app.typeface.ai";
    }
    else if (selectedEnv === "local-http") {
      return "http://localhost:3000";
    }
    else if (selectedEnv === "local-https") {
      return "https://localhost:3000";
    }
  };

  const getWidth = () => {
    return 1200;
  }

  const getUserToken = () => {
    axios
      .post(`https://partner-backend-sample.azurewebsites.net/tenants/${tenantId}/env/${selectedEnv}`)
      .then((response) => {
        console.log("Fetched User token");
        // setProjectId(response.data.projectId); 
        setUserToken(response.data.token);
      })
      .catch((err) => {
        console.error("Got error ", err);
      });
  };

  const pluginProps = {
    idp: TypefaceEmbedIDP.Typeface,
    useCase: selectedUseCase,
    typefaceOrigin: getTypefaceOrigin(),
    sourceApp: "psn",
    hideHeader: true,
    authenticate: () => {
      console.log("Invoked authenticate");
      if (userToken) {
        return Promise.resolve(userToken);
      } else {
        return Promise.reject("Failed Authentication");
      }
    },
    onEvent: (event: OnEventMessage) => {
      console.log("On Event called with data string", event)
    },
    parameters: getParameterMap(),
    onApply: (content: any) => {
      console.log("On Apply called")
      setValue(content);
      setShowDialog(false);
    },
    styles: {
      height: 700,
      width: getWidth(),
      embedFooterStyle: {
        padding: "5px 0",
      },
      showAccountSwitcher: true,
      imageStudioStyles: STYLES[selectedTheme],
    },
    onCancel: () => {
      console.log("Cancel called")
      setShowDialog(false)
    },
    outputFormat: TypefaceEmbedOutputFormat.JSON,
    applyButtonName: "Save",
    cancelButtonName: "Cancel",
    language: selectedLang,
    ...(applyRaiCheck && {
      promptCheck: (prompt: string) => {
        console.log("Prompt Check Called: ", prompt);
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              continue: promptContinue,
              ...(!promptContinue && {
                errorMessage: "Intensionally promptCheck failed",
              }),
            });
          }, 300);
        }) as Promise<ExternalRaiCheckOutput>;
      },
      imageCheck: (imageStr: string) => {
        console.log("Image Check: ", imageStr);
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              continue: imageContinue,
              ...(!imageContinue && {
                errorMessage: "Intensionally imageCheck failed",
              }),
            });
          }, 300);
        }) as Promise<ExternalRaiCheckOutput>;
      },
    }),
  };

  const triggerTypefacePlugin = () => {
    setShowDialog(true);
  };

  const handleDropdownChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value as EditorType;
    setSelectedEditorType(selectedValue);
  };

  const handleEnvironmentChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    setSelectedEnv(selectedValue);
  };

  return (
    <div className="App">
      <header className="App-header">
        <MainContainer elevation={2}>
          <Stack gap={2}>
            <FormControl>
              <InputLabel id="usecase-label">Environment:</InputLabel>
              <Select
                labelId="usecase-label"
                id="usecase"
                value={selectedEnv}
                onChange={handleEnvironmentChange}
              >
                <MenuItem value="dev">dev</MenuItem>
                <MenuItem value="prod">prod</MenuItem>
                <MenuItem value="local-http">local http</MenuItem>
                <MenuItem value="local-https">local https</MenuItem>
              </Select>
            </FormControl>
            <TextField
              value={tenantId}
              label="Tenant Id"
              onChange={(e: any) => setTenantId(e.target.value)}
            />
            <FormControl>
              <InputLabel id="style-label">Select Theme:</InputLabel>
              <Select
                labelId="style-label"
                id="theme"
                value={selectedTheme}
                onChange={(event) =>
                  setSelectedTheme(event.target.value as string)
                }
              >
                <MenuItem value={"typeface"}>Default</MenuItem>
                <MenuItem value={"msft"}>Microsoft</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="lang-label">Select Language:</InputLabel>
              <Select
                labelId="lang-label"
                id="lang"
                value={selectedLang}
                onChange={(event) =>
                  setSelectedLang(event.target.value as string)
                }
              >
                {LANGUAGES.map(language => (
                  <MenuItem key={language.id} value={language.code}>{language.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="usecase-label">Usecase:</InputLabel>
              <Select
                labelId="usecase-label"
                id="usecase"
                value={selectedUseCase}
                onChange={(event) =>
                  setSelectedUseCase(event.target.value as TypefaceEmbedUseCase)
                }
              >
                <MenuItem value={TypefaceEmbedUseCase.FeedBlend}>
                  Feed Blend
                </MenuItem>
                <MenuItem value={TypefaceEmbedUseCase.AssetEdit}>
                  Asset Edit Mask
                </MenuItem>
              </Select>
            </FormControl>
            {selectedUseCase === TypefaceEmbedUseCase.FeedBlend ? (
              <>
                <FormControl>
                  <InputLabel id="editor-label">Editor Type</InputLabel>
                  <Select
                    labelId="editor-label"
                    id="editor"
                    value={selectedEditorType}
                    onChange={handleDropdownChange}
                  >
                    <MenuItem value={EditorType.Edit}>Feed Item Blend</MenuItem>
                    <MenuItem value={EditorType.Studio}>
                      Create a product Shot
                    </MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  value={feedItemId}
                  label="Feed Item Id"
                  onChange={(e: any) => setFeedItemId(e.target.value)}
                />
                <TextField
                  value={feedId}
                  label="Feed Id"
                  onChange={(e: any) => setFeedId(e.target.value)}
                />
                <TextField
                  value={projectId}
                  label="Project Id"
                  onChange={(e: any) => setProjectId(e.target.value)}
                />
              </>
            ) : (
              <>
                <TextField
                  value={assetId}
                  label="Asset Id"
                  onChange={(e: any) => setAssetId(e.target.value)}
                />
                <TextField
                  value={assetCatalogId}
                  label="Asset Catalog Id"
                  onChange={(e: any) => setAssetCatalogId(e.target.value)}
                />
              </>
            )}
            <TextField
              value={accountId}
              label="Account Id"
              onChange={(e: any) => setAccountId(e.target.value)}
            />
            {/* Rai Check */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Typography>Apply RAI Check?</Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={applyRaiCheck}
                  exclusive
                  onChange={(_, newVal) => setApplyRaiCheck(newVal)}
                >
                  <ToggleButton value={false}>No</ToggleButton>
                  <ToggleButton value={true}>Yes</ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div>
                <Typography>Image Continue?</Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={imageContinue}
                  exclusive
                  disabled={!applyRaiCheck}
                  onChange={(_, newVal) => setImageContinue(newVal)}
                >
                  <ToggleButton value={false}>No</ToggleButton>
                  <ToggleButton value={true}>Yes</ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div>
                <Typography>Prompt Continue?</Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={promptContinue}
                  exclusive
                  disabled={!applyRaiCheck}
                  onChange={(_, newVal) => setPromptContinue(newVal)}
                >
                  <ToggleButton value={false}>No</ToggleButton>
                  <ToggleButton value={true}>Yes</ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
            {/* ---- */}
            <TriggerDialogButton
              disabled={!!userToken}
              variant="contained"
              onClick={() => getUserToken()}
            >
              Initialize
            </TriggerDialogButton>
            <TriggerDialogButton
              variant="contained"
              onClick={() => {
                triggerTypefacePlugin();
              }}
            >
              Draft with Typeface
            </TriggerDialogButton>
            <Editor
              multiline={true}
              minRows={30}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              label={"Your content here"}
            />
          </Stack>
          {!usePopUp && (
            <Dialog
              open={showDialog}
              maxWidth={false}
              PaperProps={{
                style: {
                  borderRadius: selectedTheme === "msft" ? "16px" : "0px",
                },
              }}
            >
              {/* Keep width of the plugin to around 700px for the current experience */}
              <Paper>
                {selectedUseCase === TypefaceEmbedUseCase.FeedBlend && (
                  <TypefacePluginBlend {...pluginProps} />
                )}
                {selectedUseCase === TypefaceEmbedUseCase.AssetEdit && (
                  <TypefacePluginAssetEdit {...pluginProps} />
                )}
              </Paper>
            </Dialog>
          )}
        </MainContainer>
      </header>
    </div>
  );
}

export default PSN;
